import React, { useEffect } from "react";
import { Modal as BootstrapModel } from "react-bootstrap";

function Model({
  state,
  setstate = null,
  children = null,
  title = "",
  onClose = null,
  mx = "mw-650px",
  setEdit,
  bodyClass = "px-5 my-6",
}) {
  const handleClose = () => {
    setstate && setstate(false);
    onClose && onClose();
  };

  useEffect(() => {
    if (!state) {
      handleClose();
    }
  }, [state]);

  return (
    <BootstrapModel
      show={state}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      // centered
      dialogClassName={`modal-dialog-centered ${mx}`}
      // tabIndex={-1}
    >
      <div className="modal-content">
        <BootstrapModel.Header className="modal-header" closeButton>
          <BootstrapModel.Title className="fw-bold text-center">
            {title}
          </BootstrapModel.Title>
        </BootstrapModel.Header>
        <BootstrapModel.Body className={`modal-body ${bodyClass}`}>
          {children}
        </BootstrapModel.Body>
        {/* <Button
          variant="secondary"
          className="btn btn-icon btn-sm btn-active-icon-primary"
          data-kt-users-modal-action="close"
          onClick={handleClose}
        >
          <i className="ki-duotone ki-cross fs-1">
            <span className="path1" />
            <span className="path2" />
          </i>
        </Button> */}
      </div>
    </BootstrapModel>
  );
}



export default Model;
