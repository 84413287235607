import React, { useState } from "react";
import CustomInput from "../component/CustomInput";
import * as Yup from "yup";
import CustomPasswordInput from "../component/CustomPasswordInput";
import { toast } from "react-toastify";
import useApi from "../component/ApiCall";
import { useDispatch } from "react-redux";
import { setLogin } from "../redux/userSlice";
function SignUpForm({ state, modelState }) {
  const [inputData, setInputData] = useState({
    email: "",
    password: "",
    phone: "",
    name: "",
    location: "",
    current_company: "",
    current_salary: "",
    notice_period: "",
    position: "",
    total_experience: "",
  });
  const { request, loading } = useApi();
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().required("password is required"),
    phone: Yup.string().required("phone Number is required"),
    name: Yup.string().required("Name is required"),
    location: Yup.string().required("Location is required"),
    current_company: Yup.string().required("Current Company is required"),
    current_salary: Yup.number()
      .typeError("current salary must be a number")
      .required("Current Salary is required"),
    notice_period: Yup.number()
      .required("Notice Period is required")
      .typeError("That doesn't look like a notice_period"),
    position: Yup.string().required("Position is required"),
    total_experience: Yup.number()
      .typeError("Total Experience must be a number")
      .required("Total experience is required"),
  });
  const updatefieldData = (fieldValue, fieldName) => {
    setInputData((prev) => ({ ...prev, [fieldName]: fieldValue }));
  };
  const handleValidation = (fieldValue, fieldName) => {
    console.log(fieldName, "type");
    setErrors((errors) => ({ ...errors, [fieldName]: "" }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await validationSchema.validate(inputData, { abortEarly: false });

      setErrors({});
      const url = "candidate/store";
      const sendApi = await request("POST", url, inputData);
      if (sendApi.status) {
        toast.success(sendApi.message);
        dispatch(setLogin(sendApi.data));
        modelState(false);
      } else {
        // toast.error(sendApi.message);
        setErrors(sendApi?.errors);
      }
      // refresh();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errObj = {};
        err.inner.forEach((error) => {
          errObj[error.path] = error.message;
        });
        setErrors(errObj);
      } 
    }

    // Handle form submission logic (e.g., send to API)
  };
 
  return (
    <div className="container">
      <div>
        <form action="" className="row" onSubmit={handleSubmit}>
          <div className="col-12 col-md-6 ">
            <CustomInput
              label="Name"
              name="name"
              value={inputData?.name}
              validate={true}
              error={errors?.name || ""}
              onChange={updatefieldData}
              onBlur={handleValidation}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <CustomInput
              type="email"
              label="Email"
              name="email"
              value={inputData?.email}
              validate={true}
              error={errors?.email}
              onChange={updatefieldData}
              onBlur={handleValidation}
              id="email"
            />
          </div>
          <div className="col-12 col-md-6 ">
            <CustomPasswordInput
              label="Password"
              name="password"
              value={inputData?.password}
              validate={true}
              error={errors?.password || ""}
              onChange={updatefieldData}
              onBlur={handleValidation}
              id="passwordq"
            />
          </div>
          <div className="col-12 col-md-6">
            <CustomInput
              type="text"
              label="Phone Number"
              name="phone"
              value={inputData?.phone}
              validate={true}
              error={errors?.phone || ""}
              onChange={updatefieldData}
              onBlur={handleValidation}
              id="phone"
            />
          </div>
          <div className="col-12 col-md-6">
            <CustomInput
              type="text"
              label="Location"
              name="location"
              value={inputData?.location}
              validate={true}
              error={errors?.location || ""}
              onChange={updatefieldData}
              onBlur={handleValidation}
            />
          </div>
          <div className="col-12 col-md-6">
            <CustomInput
              type="text"
              label="Current Company"
              name="current_company"
              value={inputData?.current_company}
              validate={true}
              error={errors?.current_company || ""}
              onChange={updatefieldData}
              onBlur={handleValidation}
              id="current_company"
            />
          </div>
          <div className="col-12 col-md-6">
            <CustomInput
              type="text"
              label="Position"
              name="position"
              value={inputData?.position}
              validate={true}
              error={errors?.position || ""}
              onChange={updatefieldData}
              onBlur={handleValidation}
              id="position"
            />
          </div>{" "}
          <div className="col-12 col-md-6">
            <CustomInput
              type="text"
              label="Total Experience"
              name="total_experience"
              value={inputData?.total_experience}
              validate={true}
              error={errors?.total_experience || ""}
              onChange={updatefieldData}
              onBlur={handleValidation}
              id="total_experience"
            />
          </div>
          <div className="col-12 col-md-6">
            <CustomInput
              type="text"
              label="Current Salary"
              name="current_salary"
              value={inputData?.current_salary}
              validate={true}
              error={errors?.current_salary || ""}
              onChange={updatefieldData}
              onBlur={handleValidation}
              id="current_salary"
            />
          </div>{" "}
          <div className="col-12 col-md-6">
            <CustomInput
              type="text"
              label="Notice Period"
              name="notice_period"
              value={inputData?.notice_period}
              validate={true}
              error={errors?.notice_period || ""}
              onChange={updatefieldData}
              onBlur={handleValidation}
              id="notice_period"
            />
          </div>
          <hr />
          <div className="text-center d-flex justify-content-center">
            <button
              className="btn btn-primary d-flex justify-content-center align-items-center gap-3"
              disabled={loading}
            >
              Sign up
              {loading && (
                <div className="spinner-border text-black" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignUpForm;
