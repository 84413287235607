import { useState, useCallback } from "react";
import axiosInstance from "./axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/userSlice";
import { useNavigate } from "react-router-dom";
const useApi = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ACCESS_TOKEN = useSelector((state) => state.user);
  const request = useCallback(async (method, url, data = null, config = {}) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance({
        method,
        url,
        data,
        ...config,
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN?.token}`,
          "Content-Type": "application/json",
          Accept: "application/json", // Add this if you're expecting JSON response from the server.  // If you're expecting HTML, remove this line.  // If you're expecting binary data, set 'Content-Type' to 'application/octet-stream' instead.  // If you're expecting XML, set 'Content-Type' to 'application/xml'.  // If you're expecting a custom format, specify the correct 'Content-Type' here.  // If you're expecting a different type, modify the server response handling accordingly.  // If you're expecting a different type, modify the server response handling accordingly.  // If you're expecting a different type, modify the server response handling accordingly.  // If you're expecting a different type, modify the server response handling accordingly.  // If you're expecting a different type, modify
        },
        validateStatus:status => status >= 200 && status < 300 || status === 422 
      });
      setLoading(false);
      // console.log(response.data, "maxxx");

      if (
        response.data.message &&
        response.data.message == "Token has expired"
      ) {
        // toast.error(response.data.message);
        dispatch(logout());
        navigate("/login");
        // window.location.href = "/login";
      } else {
        return response.data;
      }
    } catch (err) {
      setLoading(false);
      setError(err);
      // throw err;
      return err;
    }
  }, []);

  return { request, loading, error };
};

export default useApi;
