import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import useApi from "../component/ApiCall";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function JobView() {
  const [jobDetails, setJobDetails] = useState({});
  const { request, loading } = useApi();
  const { id } = useParams();
  const useData = useSelector((state) => state.user);
  const [refresh, setrefresh] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fun = async () => {
      const apiCal = await request("post", "candidate/jobShow", {
        job_id: id,
        candidate_id: useData ? useData?.id : null,
      });
      if (apiCal.status) {
        setJobDetails(apiCal.data);
      }
    };
    fun();
  }, [refresh]);
  const applyJob = async () => {
    // toast.error("Kindly Login for apply job");
    if (useData) {
      const apiCal = await request("post", "candidate/apply", {
        job_id: id,
        candidate_id: useData ? useData?.id : null,
      });

      if (apiCal.status) {
        toast.success(apiCal.message);
        setrefresh(!refresh);
      } else {
        toast.error(apiCal.message);
      }
    } else {
      toast.error("Kindly Login for apply job");
    }
  };
  const goBack =()=>{
    navigate('/')
  }
  return (
    <div>
      <div className="container mt-20">
        <button
          className="btn btn-info my-3"
          id="apply"
          onClick={goBack}
        >
          Back
        </button>
        <div className="card mb-12">
          <div className="card-body">
            <div className="d-md-flex">
              <div className="mb-3 mb-md-0">
                <i className="ki-duotone ki-avalanche fs-4hx me-3">
                  <span className="path1" />
                  <span className="path2" />
                </i>
              </div>
              <div className="ms-md-3 w-100 mt-3 mt-xl-1">
                <div className="d-flex justify-content-between mb-10">
                  <div>
                    <h3 className="mb-1 text-primary">{jobDetails?.title}</h3>
                    <div className="text-gray-700">
                      <span>at Airtable </span>
                      <span className="text-dark ms-2 fw-medium">
                        4.5{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={10}
                          height={10}
                          fill="currentColor"
                          className="bi bi-star-fill text-warning align-baseline"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                      </span>{" "}
                      <span className="ms-0">(324 Reviews)</span>
                    </div>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-bookmark text-muted bookmark"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                    </svg>
                  </div>
                </div>
                <div className="d-md-flex justify-content-between fs-6">
                  <div className="mb-2 mb-md-0 text-gray-700">
                    <span className="me-5">
                      <i className="ki-duotone ki-briefcase fs-3">
                        <span className="path1" />
                        <span className="path2" />
                      </i>
                      <span className="ms-1">
                        0 - {jobDetails?.experience_years} Years
                      </span>
                    </span>
                    <span className="me-5">
                      <i className="ki-duotone ki-dollar fs-3">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                      </i>
                      <span className="ms-1">{jobDetails?.salary}</span>
                    </span>
                    <span className="me-5">
                      <i className="ki-duotone ki-geolocation fs-3">
                        <span className="path1" />
                        <span className="path2" />
                      </i>
                      <span className="ms-1 ">{jobDetails?.location}</span>
                    </span>
                  </div>
                  <div className="d-flex justify-content-between gap-2">
                    {/* <button
                      className="btn  btn-outline btn-outline-primary rounded-pill"
                      //   disabled={save}
                    >
                      Save
                    </button> */}
                    {loading ? (
                      <button
                        className="btn btn-primary d-flex justify-content-center align-items-center gap-3 rounded-pill"
                        id="applydis"
                        onClick={applyJob}
                        disabled={loading}
                      >
                        Apply{" "}
                        <div
                          className="spinner-border text-black"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary d-flex justify-content-center align-items-center gap-3 rounded-pill"
                        id="apply"
                        onClick={applyJob}
                        disabled={jobDetails?.apply_status}
                      >
                        Apply
                      </button>
                    )}

                    {/* <div className="text-gray-700"> */}
                    {/* <i className="ki-duotone ki-time fs-3">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                              <span className="ms-1 ">1 day ago</span> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Job Description */}
        <div className="card mb-12">
          <div className="card-body">
            <div className="fs-5">
              <p>
                <span className="text-gray-700">
                  Job Applicants: <span className="text-gray-900">306</span>
                </span>
              </p>
            </div>

            <div>
              <div className="mt-6">
                <div className="mb-3 fs-1 fw-bold">Job Description</div>
                <div
                  className="text-gray-700 fs-4"
                  dangerouslySetInnerHTML={{
                    __html: jobDetails?.description,
                  }}
                />
              </div>
              <div className="mt-10">
                <div className="mb-3 fs-1 fw-bold">Requirements</div>
                <div
                  className="text-gray-700 fs-4"
                  dangerouslySetInnerHTML={{
                    __html: jobDetails?.requirements,
                  }}
                />
              </div>
              <div className="mt-10">
                <div className="mb-3 fs-1 fw-bold">Responsibilities</div>
                <div
                  className="text-gray-700 fs-4"
                  dangerouslySetInnerHTML={{
                    __html: jobDetails?.responsibilities,
                  }}
                />
              </div>
              <div className="mt-10">
                <div className="mb-3 fs-1 fw-bold">Benefits</div>
                <div
                  className="text-gray-700 fs-4"
                  dangerouslySetInnerHTML={{
                    __html: jobDetails?.benefits,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobView;
