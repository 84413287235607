import React, { useEffect, useState } from "react";
import JobCard from "../component/JobCard";
import useApi from "../component/ApiCall";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

function MyJob() {
  const [jobList, setJobList] = useState([]);
  const { request, loading } = useApi();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const navigate = useNavigate();
  const useData = useSelector((state) => state.user);

  useEffect(() => {
    const fun = async () => {
      const apiCal = await request("post", "candidate/myJobList", {
        limit: itemsPerPage,
        page: currentPage + 1,
        search: "",
        candidate_id: useData ? useData?.id : null,
      });
      if (apiCal.status) {
        setJobList(apiCal.data);
        setTotalPage(apiCal.paginate.totalPages);
      }
    };
    fun();
  }, [currentPage, itemsPerPage]);
  const handlePageClick = (page) => {
    setCurrentPage(page.selected);
  };
  const goback = () => {
    navigate(-1);
  };
  return (
    <div className="container py-5 ">
      <button className="btn btn-info mt-5 "  onClick={goback}>
        Back
      </button>
      <div className="text-center fw-bold fs-2 mb-5 py-2 text-primary">
        My Job List
      </div>
      <div className="d-flex flex-column gap-5 justify-content-center align-items-center bg-gray-100 rounded-2">
        {loading && (
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        {jobList.length === 0 ? (
          <div className="mt-10">
            <img
              src="https://preview.keenthemes.com/keen/demo3/assets/media/svg/illustrations/easy/2.svg"
              alt=""
            />
            <h1 className="my-3">No Job Found</h1>
          </div>
        ) : (
          <div
            className="h-600px min-h-600px scroll-y my-5 d-flex flex-column gap-3 m-auto"
            style={{ width: "88%" }}
          >
            {jobList.map((e, i) => (
              <JobCard data={e} key={i} status="apply" />
            ))}
          </div>
        )}
      </div>
      <div className="dt-paging paging_simple_numbers  my-5">
        <ReactPaginate
          previousLabel={<i className="fs-2pk page-item previous"></i>}
          nextLabel={<i className="next fs-2"></i>}
          breakLabel={".."}
          pageCount={totalPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={1}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link "}
          previousClassName={"page-item previous fs-1"}
          previousLinkClassName={"page-link previous"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
          forcePage={currentPage}
        />
      </div>
    </div>
  );
}

export default MyJob;
