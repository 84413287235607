import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import userSlice from "./userSlice";


const persistConfig = {
  key: 'root',
  storage,
};
const rootReducer = combineReducers({
  user: userSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      // Ignore specific actions and paths
      ignoredActions: ['persist/PERSIST'],
      ignoredPaths: ['register'],
    },
  }),
});


const persistor = persistStore(store);

export { store, persistor };
