import React, { useState } from "react";
import CustomInput from "../component/CustomInput";
import * as Yup from "yup";
import CustomPasswordInput from "../component/CustomPasswordInput";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import useApi from "../component/ApiCall";
import { setLogin } from "../redux/userSlice";
function LoginForm({ modelState, state }) {
  const [inputData, setInputData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const validationSchema = Yup.object({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().required("password is required"),
  });
  const dispatch = useDispatch();
  const { request, loading } = useApi();

  const updatefieldData = (fieldValue, fieldName) => {
    setInputData((prev) => ({ ...prev, [fieldName]: fieldValue }));
  };
  const handleValidation = (fieldValue, fieldName) => {
    setErrors((errors) => ({ ...errors, [fieldName]: "" }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await validationSchema.validate(inputData, { abortEarly: false });
      setErrors({});
      const url = "candidate/login";
      const sendApi = await request("POST", url, inputData);
      if (sendApi.status) {
        toast.success(sendApi.message);
        dispatch(setLogin(sendApi.data));
        modelState(false);
      } else {
        toast.error(sendApi.message);
        // setErrors(sendApi?.errors);
      }

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errObj = {};
        err.inner.forEach((error) => {
          errObj[error.path] = error.message;
        });
        setErrors(errObj);
       
      }
    }

    // Handle form submission logic (e.g., send to API)
  };
  return (
    <div className="container">
      <div className="row">
        <form action="" onSubmit={handleSubmit}>
          <div className="col-12 ">
            <CustomInput
              type="email"
              label="Email"
              name="email"
              value={inputData?.email}
              validate={true}
              error={errors.email || ""}
              onChange={updatefieldData}
              onBlur={handleValidation}
              id="email"
            />
          </div>
          <div className="col-12 ">
            <CustomPasswordInput
              label="Password"
              name="password"
              value={inputData?.password}
              validate={true}
              error={errors.password || ""}
              onChange={updatefieldData}
              onBlur={handleValidation}
              id="password"
            />
          </div>
          <hr />
          <div className="text-center d-flex justify-content-center">
            <button
              className="btn btn-primary d-flex justify-content-center align-items-center gap-3"
              disabled={loading}
            >
              Login
              {loading && (
                <div className="spinner-border text-black" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
