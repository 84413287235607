import React, { useState } from "react";
import Model from "./Model";
import CustomInput from "./CustomInput";
import LoginForm from "../pages/LoginForm";
import SignUpForm from "../pages/Signup";
import { useNavigate } from "react-router";
import { Bounce, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/userSlice";
import { NavLink } from "react-router-dom";

function Header() {
  const [formType, setformType] = useState("");
  const [modelState, setmodelState] = useState(false);
  const dispatch = useDispatch();
  const formState = (type) => {
    setformType(type);
    setmodelState(true);
  };
  const navigate = useNavigate();
  const useData = useSelector((state) => state.user);
const logoutFun = ()=>{
  dispatch(logout());
  navigate("/");
}
  return (
    <div className=" bg-white">
      <nav className="navbar  shadow-sm pb-5  p-3">
        <div className="container">
          <a
            className="navbar-brand fw-bold fs-2 cursor-pointer"
            onClick={() => navigate("/")}
          >
            Day Book
          </a>

          {useData ? (
            <div className="dropdown cursor-pointer">
              <div
                className="d-flex justify-content-center align-items-center gap-2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  <i className="ki-duotone ki-profile-circle fs-3hx">
                    <span className="path1">
                      <span className="path2">
                        <span className="path3"></span>
                      </span>
                    </span>
                  </i>
                </span>{" "}
                <span className="fw-bold text-primary"> {useData?.name}</span>
              </div>
              <ul className="dropdown-menu">
                <li>
                  <NavLink className="dropdown-item" to={'/MyJobs'}>
                    My Jobs
                  </NavLink>
                </li>
                <li>
                  <a className="dropdown-item" href="#"  onClick={logoutFun}>
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          ) : (
            <div className="d-flex gap-3">
              <button
                className="btn px-4 btn-primary"
                onClick={() => formState("login")}
              >
                Sign in
              </button>
              <button
                className="btn px-4 btn-primary"
                onClick={() => formState("create")}
              >
                Sign up
              </button>
            </div>
          )}

          <Model
            state={modelState}
            setstate={setmodelState}
            title={
              formType == "login"
                ? "Login into Daybook"
                : "Register into DayBook"
            }
          >
            {/* <LoginForm/> */}
            {formType == "login" ? (
              <LoginForm modelState={setmodelState} state={modelState} />
            ) : (
              <SignUpForm modelState={setmodelState} state={modelState} />
            )}
          </Model>
        </div>
      </nav>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        limit={1}
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </div>
  );
}

export default Header;
