// src/api/axiosInstance.js
import axios from 'axios';
const baseURL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  timeout: 10000, // Set a timeout for requests
  baseURL: baseURL, // Replace with your API base URL
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  config => {
    // Do something before request is sent, like adding auth token
    // config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => {
    // Any status code that lies within the range of 2xx causes this function to trigger
    return response;
  },
  error => {
    // Any status codes that falls outside the range of 2xx causes this function to trigger
    return Promise.reject(error);
  }
);

export default axiosInstance;
