import React from "react";

const CustomInput = ({
  label,
  name,
  value,
  validate,
  error,
  onChange,
  onBlur,
  disabled,
  regexValidation,
  // pattern,
  maxLength,
  readOnly,
  ...props
}) => {
  const handleFieldChange = (e) => {
    const inputValue = e.target.value;
    let filteredValue = inputValue;

    if (regexValidation) {
      // Keep only characters that match the allowed pattern
      filteredValue = inputValue
        .split("")
        .filter((char) => regexValidation.test(char))
        .join("");
    }

    onChange(filteredValue, name);
  };

  const handleFieldBlurChange = async (e) => {
    const inputValue = e.target.value;
    onBlur(inputValue, name);
  };

  return (
    <div className="form-floating mb-3 input-h">
      <input
        {...props}
        id={props.id?? "floatingInput"}
        autoComplete="off"
        value={value}
        onChange={handleFieldChange}
        onBlur={handleFieldBlurChange}
        placeholder=""
        className="form-control form-control-solid shadow-none"
        maxLength={maxLength}
        // pattern={pattern}
        disabled={disabled}
        readOnly={readOnly}
      />
      <label htmlFor={props.id ?? "floatingInput"}>{label}</label>
      {error && <p className="text-danger  ps-2">{error}</p>}
    </div>
  );
};

export default CustomInput;
