import moment from "moment";
import React from "react";
import { useNavigate } from "react-router";

function JobCard({ data, status = "" }) {
  const navigate = useNavigate();
  return (
    <div
      className="card cursor-pointer mx-3"
      onClick={() =>
        status == "apply" ? null : navigate(`/jobView/${data?.id}/view`)
      }
    >
      <div className="card-body">
        <div className="d-md-flex">
          <div className="mb-3 mb-md-0">
            <i className="ki-duotone ki-avalanche fs-4hx me-3">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </div>
          <div className="ms-md-3 w-100 mt-3 mt-xl-1">
            <div className="d-flex justify-content-between mb-10">
              <div>
                <h3 className="mb-1 text-primary">{data?.title}</h3>
                <div className="text-gray-700">
                  <span>at Airtable </span>
                  <span className="text-dark ms-2 fw-medium">
                    4.5{" "}
                    <i className="ki-duotone ki-star text-warning me-3">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </span>{" "}
                  <span className="ms-0">(324 Reviews)</span>
                </div>
              </div>
              <div>
              {
                status != "apply" ? (
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-bookmark text-muted bookmark"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"></path>
                </svg>
                ): (
                  <div className="badge badge-success fs-5 p-2">Applied</div>
                )
              }
              </div>
            </div>
            <div className="d-md-flex justify-content-between fs-6">
              <div className="mb-2 mb-md-0 text-gray-700">
                <span className="me-5">
                  <i className="ki-duotone ki-briefcase fs-3">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <span className="ms-1">
                    {data?.experience_years} - 5 Years
                  </span>
                </span>
                <span className="me-5">
                  <i className="ki-duotone ki-dollar fs-3">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                  </i>
                  <span className="ms-1 ">{data?.salary}</span>
                </span>
                <span className="me-5">
                  <i className="ki-duotone ki-geolocation fs-3">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <span className="ms-1 ">{data?.location}</span>
                </span>
              </div>
              <div className="text-gray-700 d-flex  gap-5 align-items-center">
                <div className="d-flex align-items-center">
                  {status == "apply" ? (
                    <div className="">
                   {moment(data?.applied_date).fromNow()}
                    </div>
                  ) : (
                    <p>
                      <i className="ki-duotone ki-time fs-3">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      <span className="ms-1 ">
                        {moment(data?.created_at).fromNow()}
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobCard;
