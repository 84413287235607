import React, { useState, useEffect } from "react";

const CustomPasswordInput = ({
  label,
  inputClassName,
  important,
  validate,
  placeholder,
  value,
  error,
  onBlur,
  onChange,
  name,
  ...props
}) => {
  const [InputType, setInputType] = useState(true);
  const [errorName, setErrorName] = useState(error);

  useEffect(() => {
    setErrorName(error);
  }, [error]);

  const handleFieldChange = async (e) => {
    const inputValue = e.target.value;
    onChange(inputValue, name);
  };

  const handleFieldBlurChange = async (e) => {
    const inputValue = e.target.value;
    onBlur(inputValue, name);
  };

  return (
    <div className="form-floating mb-3 position-relative pass">
      <input
        type={InputType ? "password" : "text"}
        id={props.id ?? "floatingInput"}
        {...props}
        value={value}
        onChange={handleFieldChange}
        onBlur={handleFieldBlurChange}
        autoComplete="off"
        placeholder={placeholder ? placeholder : ""}
        className={`form-control form-control-solid shadow-none ${
          inputClassName ? inputClassName : ""
        }`}
      />
      <label htmlFor={props.id ?? "floatingInput"}>
        {label} {important && <span className="text-danger">*</span>}
      </label>
      {InputType ? (
        <span
          className="ptxt position-absolute pe-auto cursor-pointer"
          style={{ top: 16, right: 13 }}
          onClick={() => setInputType(false)}
        >
          <i className="ki-duotone ki-eye-slash" id="currentTogglePassword">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
            <span className="path4" />
          </i>
        </span>
      ) : (
        <span
          className="ptxt position-absolute pe-auto cursor-pointer"
          style={{ top: 16, right: 13 }}
          onClick={() => setInputType(true)}
        >
          <i className="ki-duotone ki-eye" id="currentTogglePassword">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
            <span className="path4" />
          </i>
        </span>
      )}
      <p className="text-danger h-25px">{errorName || error}</p>
    </div>
  );
};

export default CustomPasswordInput;
