// userSlice.js

import { createSlice } from "@reduxjs/toolkit";

let initialState = null;

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      console.log("login", action.payload);
      return action.payload;
    },
    logout: (state, action) => {
      return initialState;
    },
  },
});

export const { setLogin, logout } = userSlice.actions;
export default userSlice.reducer;
